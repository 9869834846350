<script>
import { mapGetters } from "vuex";
import { wpService } from "@/services/wp";
import modalPhone from "@/components/account-patch-phone";
import modalPassword from "@/components/account-patch-password";
import modalEmail from "@/components/account-patch-email";
import Cleave from "cleave.js";
import _merge from "lodash/merge";
import _isEqual from "lodash/isEqual";

const profileFields = [
  // "nickname",
  "user_name_given",
  "user_name_family",
  "user_phone_fixed",
  "user_phone_mobile",
  "user_website",
  "user_sm_facebook",
  "user_sm_instagram",
  "user_sm_linkedin",
  "user_sm_twitter",
  // "user_punchline",
  "user_biography",
];

const fieldPhone = {
  render(el) {
    return el("input", {
      attrs: {
        class: "input",
        type: "tel",
        placeholder: "",
        value: this.value,
      },
    });
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      cleave: null,
    };
  },
  mounted() {
    this.cleave = new Cleave(this.$el, {
      numericOnly: true,
      delimiters: ["(", ")", " ", "-"],
      blocks: [0, 3, 0, 3, 4],
      onValueChanged: this.onInput,
    });
  },
  beforeDestroy() {
    this.cleave.destroy();
    this.cleave = null;
  },
  methods: {
    onInput(event) {
      this.$emit("input", event.target.rawValue);
    },
  },
  watch: {
    value(newValue) {
      this.cleave.setRawValue(newValue);
    },
  },
};

export default {
  name: "account-profile",

  metaInfo: {
    title: "Mi Perfil",
  },

  components: {
    // avatarUploaderComp,
    avatarPicker: () =>
      import(
        /* webpackChunkName: "avatar-picker" */ "@/components/avatar-uploader/pick.vue"
      ),
    fieldPhone,
  },

  data() {
    return {
      loading: false,
      newData: {},
    };
  },

  created() {
    this.newData = _merge({}, this.currentComputed);
  },

  watch: {
    currentComputed: {
      deep: true,
      handler() {
        this.newData = _merge({}, this.currentComputed);
      },
    },
  },

  computed: {
    ...mapGetters("config", ["user_punchline_length", "user_biography_length"]),
    ...mapGetters("session", ["currentAccount"]),
    currentComputed() {
      if (!this.currentAccount) return null;
      let currentComputed = {};
      for (let index = 0; index < profileFields.length; index++) {
        const key = profileFields[index];
        currentComputed[key] = this.currentAccount[key] || "";
      }
      return currentComputed;
    },
    changedComputed() {
      if (_isEqual(this.newData, this.currentComputed)) return null;
      let changedComputed = {};
      for (let index = 0; index < profileFields.length; index++) {
        const key = profileFields[index];
        if (this.currentComputed[key] !== this.newData[key])
          changedComputed[key] = this.newData[key];
      }
      return changedComputed;
    },
  },

  methods: {
    async deleteAvatar() {
      const loader = this.$buefy.loading.open();
      await wpService.account
        .deleteAvatar()
        .then(response => {
          console.log(response);
        })
        .catch(err => {
          console.log(err);
        });
      loader.close();
    },
    submit() {
      // console.log(this.changedComputed);
      if (!this.changedComputed) {
        return;
      }
      this.patch(this.changedComputed);
    },
    patch(body) {
      this.loading = true;
      return wpService.account.accountPatch(body).finally(() => {
        this.loading = false;
      });
    },
    // patchNickname() {
    //   this.$buefy.dialog.prompt({
    //     title: "Nombre de perfil",
    //     // message: "What's your name?",
    //     inputAttrs: {
    //       value: this.currentAccount.nickname,
    //       maxlength: 20,
    //       placeholder: "e.g. Walter Smith"
    //     },
    //     trapFocus: true,
    //     confirmText: "Guardar",
    //     cancelText: "Cerrar",
    //     onConfirm: value => {
    //       this.patch({ nickname: value }).then(() => {
    //         this.$buefy.toast.open({
    //           message: "Se actualizó su nombre de perfil.",
    //           type: "is-success"
    //         });
    //       });
    //     }
    //   });
    // },
    patchPhoneFixed() {
      this.$buefy.modal.open({
        customClass: "dialog",
        component: modalPhone,
        parent: this,
        hasModalCard: true,
        props: {
          title: "Teléfono fijo",
          value: this.currentAccount.user_phone_fixed,
        },
        events: {
          submit: value => {
            if (this.currentAccount.user_phone_fixed !== value) {
              // console.log(value);
              this.patch({ user_phone_fixed: value }).then(() => {
                this.$buefy.toast.open({
                  message: "Se actualizó su teléfono fijo.",
                  type: "is-success",
                });
              });
            }
          },
        },
      });
    },
    patchPhoneMobile() {
      this.$buefy.modal.open({
        customClass: "dialog",
        component: modalPhone,
        parent: this,
        hasModalCard: true,
        props: {
          title: "Teléfono movil",
          value: this.currentAccount.user_phone_mobile,
        },
        events: {
          submit: value => {
            if (this.currentAccount.user_phone_mobile !== value) {
              // console.log(value);
              this.patch({ user_phone_mobile: value }).then(() => {
                this.$buefy.toast.open({
                  message: "Se actualizó su teléfono móvil.",
                  type: "is-success",
                });
              });
            }
          },
        },
      });
    },
    patchPassword() {
      this.$buefy.modal.open({
        customClass: "dialog",
        component: modalPassword,
        parent: this,
        hasModalCard: true,
        trapFocus: true,
        props: {
          title: "Cambio de contraseña",
        },
        events: {
          submit: value => {
            // console.log(value);
            this.patch({ password: value }).then(() => {
              this.$buefy.toast.open({
                message: "Se actualizó su contraseña.",
                type: "is-success",
              });
            });
          },
        },
      });
    },
    patchEmail() {
      this.$buefy.modal.open({
        customClass: "dialog",
        component: modalEmail,
        parent: this,
        hasModalCard: true,
        trapFocus: true,
      });
    },
  },
};
</script>

<template>
  <div id="account-profile" class="ui_padding">
    <b-loading :active="loading" />
    <div class="container">
      <h1 class="page__title">Mi Perfil</h1>
      <div class="sections" v-if="currentAccount">
        <div class="section section--photo">
          <h3 class="section__title">Foto de perfil</h3>
          <div class="avatar">
            <div class="avatar-container">
              <img class="size-holder" src="@/assets/sizeholder-square.png" />
              <picture v-if="currentAccount.user_avatar">
                <source :srcset="currentAccount.user_avatar.srcset" />
                <img :src="currentAccount.user_avatar.src" />
              </picture>
              <picture v-else>
                <img src="@/assets/avatar-square.svg" />
              </picture>
            </div>
          </div>
          <div class="buttons instructions">
            <!-- <p>
              Formato: archivo JPEG<br />
              Tamaño del archivo: inferior a 10 MB<br />
              Dimensiones: por lo menos 400 por 400 píxeles
            </p> -->
            <avatarPicker text="Cargar imagen" />
            <button class="button" @click="deleteAvatar">Eliminar foto</button>
          </div>
        </div>
        <div class="section section--generals">
          <h3 class="section__title">Información general</h3>
          <div class="fields">
            <div class="field">
              <div class="label">Nombre</div>
              <b-input
                placeholder="eg: John"
                expanded
                v-model="newData.user_name_given"
              />
            </div>
            <div class="field">
              <div class="label">Apellido</div>
              <b-input
                placeholder="eg: Doe"
                expanded
                v-model="newData.user_name_family"
              />
            </div>
            <!-- <div class="field">
              <span class="label">Descripción corta, frase o slogan</span>
              <input
                class="input"
                v-model="newData.user_punchline"
                :maxlength="user_punchline_length"
              />
              <div>
                <b>{{ newData.user_punchline.length }}</b>
                <span> / </span>
                <span>{{ user_punchline_length }}</span>
              </div>
            </div> -->
            <div class="field field--phone">
              <div class="label">Teléfono fijo</div>
              <fieldPhone v-model.lazy="newData.user_phone_fixed" />
            </div>
            <div class="field field--phone">
              <div class="label">Teléfono movil</div>
              <fieldPhone v-model.lazy="newData.user_phone_mobile" />
            </div>
            <div class="field greedy">
              <div class="label">Sitio web</div>
              <b-field>
                <p class="control">
                  <span class="button is-static">http://</span>
                </p>
                <b-input
                  placeholder="www.tupaginaweb.com"
                  expanded
                  v-model="newData.user_website"
                />
              </b-field>
            </div>
          </div>
          <button
            class="button submit is-link"
            :disabled="!changedComputed"
            v-text="'Guardar cambios'"
            @click="submit()"
          />
        </div>
        <div class="section">
          <h3 class="section__title">Redes sociales</h3>
          <div class="field greedy">
            <div class="label"><i class="fab fa-facebook"></i> Facebook</div>
            <b-field>
              <p class="control">
                <span class="button is-static">facebook.com/</span>
              </p>
              <b-input expanded v-model="newData.user_sm_facebook" />
            </b-field>
          </div>
          <div class="field greedy">
            <div class="label"><i class="fab fa-instagram"></i> Instagram</div>
            <b-field>
              <p class="control">
                <span class="button is-static">instagram.com/</span>
              </p>
              <b-input expanded v-model="newData.user_sm_instagram" />
            </b-field>
          </div>
          <div class="field greedy">
            <div class="label"><i class="fab fa-linkedin"></i> Linkedin</div>
            <b-field>
              <p class="control">
                <span class="button is-static">linkedin.com/in/</span>
              </p>
              <b-input expanded v-model="newData.user_sm_linkedin" />
            </b-field>
          </div>
          <div class="field greedy">
            <div class="label">
              <i class="fab fa-twitter-square"></i> Twitter
            </div>
            <b-field>
              <p class="control">
                <span class="button is-static">@</span>
              </p>
              <b-input expanded v-model="newData.user_sm_twitter" />
            </b-field>
          </div>
          <button
            class="button submit is-link"
            :disabled="!changedComputed"
            v-text="'Guardar cambios'"
            @click="submit()"
          />
        </div>
        <div class="section">
          <h3 class="section__title">Biografía</h3>
          <textarea
            class="textarea"
            rows="10"
            v-model="newData.user_biography"
            :maxlength="user_biography_length"
          />
          <div>
            <b>{{ newData.user_biography.length }}</b>
            <span> / </span>
            <span>{{ user_biography_length }}</span>
          </div>
          <button
            class="button submit is-link"
            :disabled="!changedComputed"
            v-text="'Guardar cambios'"
            @click="submit()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#account-profile {
  .ibox {
    .databox {
      &:not(:last-child) {
        border-bottom: 1px solid rgba(12, 18, 28, 0.12);
      }
      > * {
        // display: block;
        padding: 0.75rem 1rem;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
#account-profile > .container {
  max-width: 32rem;
}
.submit {
  margin-top: 1rem;
}
// .sections > .section {
//   max-width: 32rem;
// }
.section--photo {
  .avatar {
    width: 10rem;
    // border-radius: 1rem;
    // padding: 1rem;
    // border: 1px solid #eee;
    .avatar-container {
      overflow: hidden;
      position: relative;
    }
    .size-holder {
      width: 100%;
      display: block;
    }
    picture {
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 0.25rem;
      overflow: hidden;
      img {
        display: block;
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
      }
    }
  }
  .instructions {
    font-size: 0.875em;
    padding-top: 1rem;
  }
}
.section--generals {
  .fields {
    display: grid;
    grid-column-gap: 1rem;
    .field.greedy {
      grid-column: span 2;
    }
  }
}
// @media (min-width: 32em) {
//   .section--photo {
//     display: flex;
//     > :first-child {
//       margin-right: 1rem;
//     }
//   }
// }

@media (min-width: 64em) {
  #account-profile {
    > .container {
      max-width: 48em;
    }
    .section {
      width: calc(100% - 16rem);
      max-width: 30em;
    }
    .section--photo {
      width: 13rem;
      // position: absolute;
      float: right;
      display: block;
      .buttons /deep/ {
        .button {
          margin-right: 0;
        }
      }
      .avatar {
        width: 100%;
        margin: 0;
      }
      .upload /deep/ {
        width: 100%;
        font-weight: bold;
        > * {
          width: 100%;
        }
      }
    }
  }
}
</style>
