<script>
import Cleave from "cleave.js";

/**
 * We add a new instance of Cleave when the element
 * is bound or updated, and destroy it when it's unbound.
 */
const cleave = {
  name: "cleave",
  bind(el, binding) {
    // console.log("el", el);
    // console.log("binding", binding);
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
export default {
  directives: { cleave },

  props: {
    title: {
      type: String,
      default: "Teléfono",
    },
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    autocomplete: {
      default: false,
      type: Boolean,
    },
    phoneLength: {
      default: 10,
      type: Number,
    },
    required: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      maskOptions: {
        numericOnly: true,
        delimiters: ["(", ")", " ", "-"],
        blocks: [0, 3, 0, 3, 4],
      },
      errorRaw: false,
      newValue: this.value,
      // newMask: undefined,
      showValidation: false,
    };
  },

  computed: {
    validation() {
      let validation = {
        valid: true,
        input: this.newValue,
        valClass: "",
        valMessage: "",
      };
      if (validation.input.length != this.phoneLength) {
        validation.valid = false;
        validation.valClass = "is-danger";
        validation.valMessage = validation.input.length
          ? "El teléfono no está completo."
          : "Introduce un teléfono de contacto.";
        validation.input = undefined;
      }
      return validation;
    },
    valClass() {
      return this.showValidation ? this.validation.valClass : "";
    },
    valMessage() {
      return this.showValidation ? this.validation.valMessage : "";
    },
  },

  methods: {
    submit() {
      if (this.validation) {
        this.$emit("submit", this.newValue);
        this.$parent.close();
      }
      // console.log("submit");
    },
    emit() {
      this.$emit("input", this.validation);
    },
    onBlur() {
      this.showValidation = true;
    },
    onInput(event) {
      this.showValidation = false;
      // this.newMask = event.target._vCleave.getFormattedValue();
      this.newValue = event.target._vCleave.getRawValue();
    },
  },
};
</script>

<template>
  <form action="" @submit.prevent="submit">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </header>
      <section class="modal-card-body">
        <b-field :label="label" :type="valClass" :message="valMessage">
          <b-input
            ref="phone"
            placeholder="(8XX) XXX-XXXX"
            type="tel"
            :value="value"
            v-cleave="maskOptions"
            @input.native="onInput"
            v-on:blur="onBlur"
            :autocomplete="autocomplete ? 'on' : 'off'"
          />
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">
          Cerrar
        </button>
        <button class="button is-primary">Guardar</button>
      </footer>
    </div>
  </form>
</template>
